.MuiDropzoneArea-root{
    color: #757575;
    border: 1px dashed #4f4f4f !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiDropzoneArea-root > input{
    font-size: small !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiDropzonePreviewList-root.css-a5rdam-MuiGrid-root{
    padding: 2px 6px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root.MuiDropzoneSnackbar-successAlert.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root{
    background-color: #004780;

}

.MuiSnackbarContent-message.css-1exqwzz-MuiSnackbarContent-message{
    background-color: #004780;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiDropzoneSnackbar-icon.css-i4bv87-MuiSvgIcon-root{
    margin-right: 4px;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-outlinedDefault.css-onze7a-MuiButtonBase-root-MuiChip-root{
    background-color: #0d7ad3;
    color: white;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root{
    color:#d7d1d1;
}